<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <AddNewButton :label="$t('Add')" @click="addNewItem" />
<!--            <MultipleDeleteButton v-show="$store.state.user.role === 0 || $store.state.user.role === 1"-->
<!--                                  :label="$t('Delete')"-->
<!--                                  @click="confirmItemsMultipleDelete"-->
<!--                                  :disabled="!selectedItems || !selectedItems.length" />-->
          </template>
<!--          <template v-slot:right>-->
<!--            <SelectButton @click="changeDataType($event)" v-model="selectedDataType" :options="dataTypes" class="p-pt-1 p-pb-1"/>-->
<!--          </template>-->
          <template #end>
            <router-link :to="{ path: `/customers-archive` }">
              <Button label="Archive" icon="ti-archive" class="p-button-info p-button-raised p-mr-2"/>
            </router-link>
          </template>
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"
                          :tableName="$t('Customers')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :searchData="searchData"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          @search-items="searchItems"
                          :headers="[
              {name: 'id', title: '#',  width: '6%', sortable: true},
              {name: '', title: $t('Customer'),  width: '',sortable: false},
              {name: 'language', class: 'ti-world', width: '60px', sortable: true},
              {name: 'orders_count', class: 'ti-hummer', width: '60px', sortable: true},
              {name: 'price_offers_count', class: 'ti-clipboard', width: '60px', sortable: true},
              {name: 'cars_count', class: 'ti-car', width: '60px', sortable: true},
              {name: 'phone_number', title: $t('Phone'), sortable: true},
              {name: 'email', title: $t('Email'), sortable: true},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
<!--          <template v-slot:multiselectHeader>-->
<!--            <th v-show="$store.state.user.role === 0 || $store.state.user.role === 1" class="multiSelect-column-header">-->
<!--              <Checkbox @click="selectAll" :value="0" v-model="selectAllCheckbox"/>-->
<!--            </th>-->
<!--          </template>-->
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'p-highlight': isItemSelected(item.id), 'updated-item': isItemUpdated(item.id)}">
<!--              <td v-show="$store.state.user.role === 0 || $store.state.user.role === 1">-->
<!--                <Checkbox :value="item.id" v-model="selectedItems"/>-->
<!--              </td>-->
              <td>{{ item.id }}</td>
              <td>
                <span v-if="item.company_name">
                  <div class="datatable-company-name" :class="{'datatable-company-name--white':isItemSelected(item.id)}">{{  item.company_name }}</div>
                  <div class="table-small-text">{{ item.first_name }} {{ item.last_name }}</div>
                </span>
                <span v-else>
                  <div>{{ item.first_name }} {{ item.last_name }}</div>
                </span>
              </td>
              <td>
                <span :class="'flag--datatable flag flag-' + item.flag_code"></span>
              </td>
              <td>
                {{ item.orders_count || 0 }}
              </td>
              <td>
                {{ item.offers_count || 0 }}
              </td>
              <td>
                {{ item.cars_count || 0 }}
              </td>
              <td>{{ item.phone_code || '' }} {{ item.phone_number || '' }}</td>
              <td style="white-space: pre-wrap">{{ item.email }}</td>

              <td class="table-date-line">
                {{ formatDate(item.created_at) }},
                <span v-if="item.creator" class="table-date-line">
                  {{ item.creator.first_name }} {{ item.creator.last_name }}
                  <span v-if="item.creator.company_name">({{ item.creator.company_name }})</span>
                </span>
              </td>
              <td>
                <div class="table__actions-column-data">
                  <router-link :to="{ path: `/users/${item.id}` }" target="_blank"><ViewButton/></router-link>
                  <EditButton :disabled="item.role === 0 && $store.state.user.role !== 0"
                              @click="editItem(item)" />
                  <DeleteButton :disabled="item.role === 0"
                                @click="confirmItemDelete({id: item.id, first_name: item.first_name, last_name: item.last_name,role: item.role,company_name: item.company_name ? item.company_name : null})"
                                v-show="$store.state.user.role === 0 || $store.state.user.role === 1"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <!--  Mobile DataTable -->
        <MobileDataTable v-else-if="!isLoading && $store.state.tabletLayout"
                         :tableName="$t('Customers')"
                         :itemsArrayIsEmpty="itemsArrayIsEmpty"
                         :searchData="searchData"
                         @search-items="searchItems">
<!--          <template v-slot:multiselectHeader >-->
<!--              <Checkbox v-show="$store.state.user.role === 0 || $store.state.user.role === 1" @click="selectAll" :value="0" v-model="selectAllCheckbox"/>-->
<!--          </template>-->
          <template v-slot:body>
            <tr :class="{'p-highlight': isItemSelected(item.id), 'updated-item': isItemUpdated(item.id)}"
                v-for="(item, index) of items"
                :key="index">
              <td>
<!--                <div v-if="$store.state.user.role === 0 || $store.state.user.role === 1" class="p-mb-3">-->
<!--                  <Checkbox :value="item.id" v-model="selectedItems"/>-->
<!--                </div>-->
                <div class="p-mb-3 p-d-flex p-ai-center p-pt-2">
                  <div class="p-text-bold mobile-datatable__title">{{ $t('Customer') }}:</div>
                  <div class="mobile-datatable__text p-d-flex p-ai-center p-flex-wrap">
<!--                    <div>{{ item.first_name }} {{ item.last_name }}</div>-->
                    <span v-if="item.company_name">
                      <span class="datatable-company-name p-mr-2" :class="{'datatable-company-name--white':isItemSelected(item.id)}">{{  item.company_name }}</span>
                      <span class="table-small-text">{{ item.first_name }} {{ item.last_name }}</span>
                    </span>
                    <span v-else>
                      <div>{{ item.first_name }} {{ item.last_name }}</div>
                    </span>
                    <div><span :class="'flag--datatable flag flag-' + item.flag_code" class="p-ml-1"></span></div>
                  </div>
                </div>
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Role') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ computedRole(item.role) }}</div>-->
<!--                </div>-->
                <div class="p-mb-3 p-d-flex p-ai-center">
                  <div class="p-text-bold mobile-datatable__title">{{ $t('Counters') }}:</div>
                  <div class="mobile-datatable__text p-d-flex p-ai-center">
                    <div class="p-d-flex p-ai-center">
                      <div>
                        <span class="ti-hummer"></span>
                        <span class="p-text-bold p-ml-1">{{ item.orders_count || 0 }}</span>
                      </div>
                      <div class="p-ml-3">
                        <span class="ti-clipboard"></span>
                        <span class="p-text-bold p-ml-1">{{ item.offers_count || 0 }}</span>
                      </div>
                      <div class="p-ml-3">
                        <span class="ti-car"></span>
                        <span class="p-text-bold p-ml-1">{{ item.cars_count || 0 }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-mb-3 p-d-flex p-ai-center">
                  <div class="p-text-bold mobile-datatable__title">{{ $t('Phone') }}:</div>
                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ item.phone_code || '' }} {{ item.phone_number || '' }}</div>
                </div>
                <div class="p-mb-3 p-d-flex p-ai-center">
                  <div class="p-text-bold mobile-datatable__title">{{ $t('Email') }}:</div>
                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ item.email }}</div>
                </div>
                <div class="p-mb-3 p-d-flex p-ai-center">
                  <div class="p-text-bold mobile-datatable__title">{{ $t('Created') }}:</div>
                  <div class="mobile-datatable__text p-d-flex p-ai-center table-date-line">
                    <span class="p-mr-1">{{ formatDate(item.created_at) }}</span>
                    <span v-if="item.creator" class="table-date-line">
                      {{ item.creator.first_name }} {{ item.creator.last_name }}
                      <span v-if="item.creator.company_name">({{ item.creator.company_name }})</span>
                    </span>
                  </div>
                </div>
                <div class="p-m-2 p-d-flex p-jc-center">
                  <router-link :to="{ path: `/users/${item.id}` }" target="_blank"><ViewButton/></router-link>
                  <EditButton :disabled="item.role === 0 && $store.state.user.role !== 0"
                              @click="editItem(item)" />
                  <DeleteButton :disabled="item.role === 0"
                                @click="confirmItemDelete({id: item.id, first_name: item.first_name, last_name: item.last_name,role: item.role,company_name: item.company_name ? item.company_name : null})"
                                v-show="$store.state.user.role === 0 || $store.state.user.role === 1"/>
                </div>
              </td>
            </tr>
          </template>
        </MobileDataTable>

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>


<!--        <IndividualModal-->
<!--            :visible="firstDataModal"-->
<!--            @close="closeFirstDataModal"-->
<!--            @update-items="updateItems"-->
<!--            :item="firstItem">-->
<!--        </IndividualModal>-->
<!--&lt;!&ndash;        :firstLayerOpened="firstLayerOpened"&ndash;&gt;-->
<!--&lt;!&ndash;        :secondLayerOpened="secondLayerOpened"&ndash;&gt;-->
<!--&lt;!&ndash;        @close-first-layer="closeFirstLayer"&ndash;&gt;-->
<!--&lt;!&ndash;        @toggle-second-layer="toggleSecondLayer"&ndash;&gt;-->

        <CustomerModal :visible="itemModal"
                     :item="item"
                     @update-items="updateItems"
                     @close="closeItemModal">
        </CustomerModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>

<!--        <ConfirmMultipleDeleteModal-->
<!--            :visible="confirmItemsMultipleDeleteModal"-->
<!--            @close="closeConfirmItemsMultipleDeleteModal"-->
<!--            @delete-items="deleteMultipleItems">-->
<!--        </ConfirmMultipleDeleteModal>-->


<!--        <CompanyModal-->
<!--            :visible="secondDataModal"-->
<!--            @close="closeSecondDataModal"-->
<!--            @update-items="updateItems"-->
<!--            :item="secondItem">-->
<!--        </CompanyModal>-->
<!--        <ConfirmDeleteModal-->
<!--            :visible="confirmSecondItemDeleteModal"-->
<!--            @close="closeConfirmSecondItemDeleteModal"-->
<!--            @delete-item="deleteSecondItem"-->
<!--            :data="itemToDelete.name">-->
<!--        </ConfirmDeleteModal>-->
<!--        <ConfirmMultipleDeleteModal-->
<!--            :visible="confirmSecondItemsMultipleDeleteModal"-->
<!--            @close="closeConfirmSecondItemsMultipleDeleteModal"-->
<!--            @delete-items="deleteMultipleSecondItems">-->
<!--        </ConfirmMultipleDeleteModal>-->
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import CommonTranslations from '@/translations/CommonTranslations'
import CustomerModal from '@/pages/users/components/CustomerModal'
import roles from '@/translations/roles'

export default {
  components: { CustomerModal },
	data() {
		return {
      isLoading: true,
      selectedDataType: null,
      dataTypes: [],
      dataTypesTranslation: 'customerTypes',
      fieldsExpand: 'creator, address',
      // data: [
      //   {
      //     name: 'individuals',
      //     apiLink: 'user/clients',
      //     fieldsExpand: 'company_names,creator,address,branch',
      //   },
      //   {
      //     name: 'companies',
      //     apiLink: 'company/all',
      //     fieldsExpand: 'user_names,address,creator',
      //   },
      // ],
      apiLink: 'user/get-customers',
      searchData: '',
      sort: {
        column: 'id',
        direction: '-',
      },
      isDataTypeChanged: false,
      selectedCountry: null,
			items: [],
      itemModal: false,
      itemDeleteModal: false,
      confirmItemDeleteModal: false,
      // confirmItemsMultipleDeleteModal: false,
			item: {},
      updatedItem: null,
      itemToDelete: {},
			selectedItems: [],
      selectAllCheckbox: [],
			submitted: false,
      pagination: {
        totalCount: null,
        pageCount: null,
        currentPage: 1,
        perPage: 25
      },
		}
	},
  props: {
    user: Object,
  },
  watch: {
    '$route'() {
      this.checkRouteQuery()
      if (this.$route.path === '/login' || this.$route.path !== '/customers') {
        return false
      }
      // this.checkRouteDataQuery()
      this.getItems()
    },
    selectedItems() {
      if (this.checkAllItemsAreSelected() && this.selectedItems.length) {
        this.selectAllCheckbox.push(0)
      } else {
        this.selectAllCheckbox = []
      }
    },
    '$i18n.locale'() {
      // this.loadDataType()
      // this.checkRouteDataQuery()
      this.unselectAllItems()
    },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.itemModal) {
          this.closeItemModal()
        }
        if (this.confirmItemDeleteModal) {
          this.closeConfirmItemDeleteModal()
        }
        // if (this.confirmItemsMultipleDeleteModal) {
        //   this.closeConfirmItemsMultipleDeleteModal()
        // }

        // if (this.firstDataModal) {
        //   this.closeFirstDataModal()
        // }
        // if (this.confirmFirstItemDeleteModal) {
        //   this.closeConfirmFirstItemDeleteModal()
        // }
        // if (this.confirmFirstItemsMultipleDeleteModal) {
        //   this.closeConfirmFirstItemsMultipleDeleteModal()
        // }
        // if (this.secondDataModal) {
        //   this.closeSecondDataModal()
        // }
        // if (this.confirmSecondItemDeleteModal) {
        //   this.closeConfirmSecondItemDeleteModal()
        // }
        // if (this.confirmSecondItemsMultipleDeleteModal) {
        //   this.closeConfirmSecondItemsMultipleDeleteModal()
        // }
      }
    },
  },
  mounted() {
    // if (!this.$store.state.user?.role) return false
    // this.loadDataType()
    this.checkRouteQuery()
    // this.checkRouteDataQuery()

    this.getItems()
    // this.getFirstData()
    // this.getSecondData()
	},
  unmounted() {
    this.items = []
  },
  methods: {
    // check() {
    //
    // },
    // close() {
    //   this.closeFirstLayer()
    // },
    // closeFirstLayer() {
    //   this.firstDataModal = false
    //   this.firstLayerOpened = false
    // },
    // toggleSecondLayer(data) {
    //   this.secondLayerOpened = data
    // },
    updateItems(user, clearItem = false) {
      // if (id) {
      //   if (isSecondItem) {
      //     this.updatedSecondItem = id
      //     this.updatedFirstItem = null
      //   } else {
      //     this.updatedFirstItem = id
      //     this.updatedSecondItem = null
      //   }
      // }
      // if (clearItem) {
      //   this.firstItem = {}
      //   this.secondItem = {}
      //   this.submitted = false
      // }
      if (user) {
        this.updatedItem = user.id
      }
      if (clearItem) {
        this.item = {}
        this.submitted = false
      }

      this.getItems()
      // this.getFirstData()
      // this.getSecondData()
    },
    loadDataType() {
      this.dataTypes = CommonTranslations[this.dataTypesTranslation][this.$i18n.locale]
      if (this.changeDataTypeClick) {
        this.selectedDataType = CommonTranslations[this.dataTypesTranslation][this.$i18n.locale][this.dataTypeIndex]
      } else {
        this.selectedDataType = CommonTranslations[this.dataTypesTranslation][this.$i18n.locale][0]
      }
    },
    // changeDataType() {
    //   this.changeDataTypeClick = true
    //   this.resetAllRouterQueries()
    //
    //   if (this.selectedDataTypeTarget === event.target.textContent) {
    //     return false
    //   }
    //   this.selectedDataTypeTarget = event.target.textContent
    //
    //   if (this.selectedDataType === CommonTranslations[this.dataTypesTranslation][this.$i18n.locale][0]) {
    //
    //     this.changeToFirstData()
    //     this.$router.push({
    //       route: this.$route.path,
    //       query: {
    //         data: this.data[0].name,
    //       }
    //     })
    //   } else if (this.selectedDataType === CommonTranslations[this.dataTypesTranslation][this.$i18n.locale][1]) {
    //     this.changeToSecondData()
    //     this.$router.push({
    //       route: this.$route.path,
    //       query: {
    //         data: this.data[1].name,
    //       }
    //     })
    //   }
    //   this.unselectAllItems()
    // },
    changeToFirstData() {
      this.selectedDataType = CommonTranslations[this.dataTypesTranslation][this.$i18n.locale][0]
      this.dataTypeIndex = 0
      this.apiLink = this.data[0].apiLink
      this.fieldsExpand = this.data[0].fieldsExpand
      if (this.changeDataTypeClick) {
        this.items = this.firstData
        this.changeDataTypeClick = false
      }
    },
    changeToSecondData() {
      this.selectedDataType = CommonTranslations[this.dataTypesTranslation][this.$i18n.locale][1]
      this.dataTypeIndex = 1
      this.apiLink = this.data[1].apiLink
      this.fieldsExpand = this.data[1].fieldsExpand
      if (this.changeDataTypeClick) {
        this.items = this.secondData
        this.changeDataTypeClick = false
      }
    },
    // checkRouteDataQuery() {
    //   if (!this.$route.query.data) {
    //     this.changeToFirstData()
    //   } else {
    //     if (this.$route.query.data === this.data[0].name) {
    //       this.changeToFirstData()
    //     } else if (this.$route.query.data === this.data[1].name) {
    //       this.changeToSecondData()
    //     }
    //   }
    // },
    checkRouteQuery() {
      if (this.$route.query.page) {
        this.pagination.currentPage = +this.$route.query.page
      } else {
        this.pagination.currentPage = 1
      }
      if (this.$route.query.perPage) {
        this.pagination.perPage = +this.$route.query.perPage
      } else {
        this.pagination.perPage = 25
      }
      if (this.$route.query.sort) {
        const firstSign = this.$route.query.sort[0]
        if (firstSign === '-') {
          this.sort.direction = '-'
          let sortColumn = this.$route.query.sort.split('')
          this.sort.column = sortColumn.splice(1).join('')
        } else {
          this.sort.direction = ''
          this.sort.column = this.$route.query.sort
        }
      }

      if (this.$route.query.search) {
        this.searchData = this.$route.query.search
      } else {
        this.searchData = ''
      }
    },
    searchItems(searchData) {
      this.searchData = searchData
      this.$router.push({
        route: this.$route.path,
        query: {
          ...this.$route.query,
          search: this.searchData,
        }
      })
    },
    sortItems(columnName) {
      if (this.sort.column !== columnName) {
        this.sort.direction = ''
      } else {
        this.sort.direction = this.sort.direction === '' ? '-' : '';
      }
      this.sort.column = columnName

      this.$router.push({
        route: this.$route.path,
        query: {
          ...this.$route.query,
          sort: this.sort.direction + this.sort.column
        }
      })
    },
    selectAll() {
      if (this.selectAllCheckbox.length) {
        this.unselectAllItems()
      } else {
        this.selectAllItems()
      }
    },
    selectAllItems() {
      this.selectedItems = []
      this.items.forEach(item => this.selectedItems.push(item.id))
    },
    unselectAllItems() {
      this.selectAllCheckbox = this.selectedItems = []
    },
    isItemSelected(itemId) {
      return this.selectedItems.indexOf(itemId) >= 0
    },
    isItemUpdated(itemId) {
      return this.updatedItem === itemId
    },
    // isSecondItemUpdated(itemId) {
    //   return this.updatedSecondItem === itemId
    // },
    checkAllItemsAreSelected() {
      return this.selectedItems.length === this.items.length
    },
    async getItems() {
      try {
        const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        if (status === 200) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch (err) {
        console.log(err)
      }
      this.unselectAllItems()
      this.isLoading = false
    },
    // async getFirstData() {
    //   try {
    //     const { status, data } = await httpClient(`${this.data[0].apiLink}?sort=${this.sort.direction + this.sort.column}&expand=${this.data[0].fieldsExpand}`)
    //     if (status === 200) {
    //       this.firstData = data
    //       // console.log(this.firstData)
    //     }
    //   } catch (err) {
    //     console.log(err)
    //   }
    //   this.unselectAllItems()
    // },
    // async getSecondData() {
    //   try {
    //     const { status, data } = await httpClient(`${this.data[1].apiLink}?sort=${this.sort.direction + this.sort.column}&expand=${this.data[1].fieldsExpand}`)
    //     if (status === 200) {
    //       this.secondData = data
    //     }
    //   } catch (err) {
    //     console.log(err)
    //   }
    //   this.unselectAllItems()
    // },
		formatCurrency(value) {
			if(value) {
        return value.toLocaleString(this.$store.state.branchData.locale, {style: 'currency', currency: this.$store.state.branchData.currency});
      }
		},
    formatDate(date) {
      if (date) {
        const event = new Date(date * 1000);
        return event.toLocaleString(this.$store.state.branchData.locale, { timeZone: this.$store.state.branchData.timezone })
      }
    },
    closeItemModal() {
      this.itemModal = false
      if (this.$store.state.firstLayerIsOpened === true ) {
        this.$store.commit('toggleFirstLayer', false)
      }
      this.item = {}
      this.submitted = false
    },
    // closeFirstDataModal() {
    //   this.firstDataModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    //   this.firstItem = {}
    //   this.submitted = false
    // },
    // closeSecondDataModal() {
    //   this.secondDataModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    //   this.secondItem = {}
    //   this.submitted = false
    // },
    addNewItem() {
			this.item = {}
			this.submitted = false
			this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
		},
    // openNewSecondDataModal() {
    //   this.secondItem = {}
		// 	this.submitted = false
		// 	this.secondDataModal = true
    //   this.$store.commit('toggleFirstLayer', true)
		// },
		// saveItem() {
		// 	this.submitted = true;
    //
		// 	if (this.item.name.trim()) {
		// 		if (this.item.id) {
		// 			this.item.inventoryStatus = this.item.inventoryStatus.value ? this.item.inventoryStatus.value: this.item.inventoryStatus;
		// 			this.items[this.findIndexById(this.item.id)] = this.item;
		// 			this.$toast.add({severity:'success', summary: 'Successful', detail: 'item Updated', life: 3000});
		// 		}
		// 		else {
		// 			this.item.id = this.createId();
		// 			this.item.code = this.createId();
		// 			this.item.image = 'item-placeholder.svg';
		// 			this.item.inventoryStatus = this.item.inventoryStatus ? this.item.inventoryStatus.value : 'INSTOCK';
		// 			this.items.push(this.item);
		// 			this.$toast.add({severity:'success', summary: 'Successful', detail: 'item Created', life: 3000});
		// 		}
    //
		// 		this.itemDialog = false;
		// 		this.item = {};
		// 	}
		// },
    // viewFirstData(itemId) {
    //   console.log(itemId)
    //   // this.item = item;
    //   // this.firstDataModal = true;
    //   this.$router.push({ path: `user/${itemId}`, target: "_blank vue"})
    // },
    // viewSecondData(item) {
    //   this.item = item;
    //   this.secondDataModal = true;
    // },
		editItem(item) {
			this.item = item
      this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
		},
		// editSecondData(item) {
		// 	this.secondItem = item
		// 	this.secondDataModal = true
    //   this.$store.commit('toggleFirstLayer', true)
		// },
    confirmItemDelete(itemToDelete) {
      this.itemToDelete = itemToDelete
      this.confirmItemDeleteModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeConfirmItemDeleteModal() {
      this.itemToDelete = {}
      this.confirmItemDeleteModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    // confirmFirstItemDelete(itemToDelete) {
    //   this.itemToDelete = itemToDelete
    //   this.confirmFirstItemDeleteModal = true
    //   this.$store.commit('toggleFirstLayer', true)
		// },
    // closeConfirmFirstItemDeleteModal() {
    //   this.itemToDelete = {}
    //   this.confirmFirstItemDeleteModal = false
    //   this.$store.commit('toggleFirstLayer', false)
		// },
    async deleteItem() {
      try {
        const { status } = await httpClient.post('user/delete-user', {id: this.itemToDelete.id})
        if (status === 204) {
          this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750});
          this.updatedItem = null

          this.searchData = ''
          this.$router.push({
            route: this.$route.path,
            query: {
              ...this.$route.query,
              search: '',
            }
          })

          this.getItems()
          this.closeConfirmItemDeleteModal()
        }
      } catch(err) {
        console.log(err)
      }
    },
    // confirmItemsMultipleDelete() {
    //   this.confirmItemsMultipleDeleteModal = true
    //   this.$store.commit('toggleFirstLayer', true)
    // },
    // closeConfirmItemsMultipleDeleteModal() {
    //   this.itemToDelete = {}
    //   this.confirmItemsMultipleDeleteModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    // },
    // async deleteMultipleItems() {
    //   const ids = this.selectedItems.map(item => item)
    //   try {
    //     const { status } = await httpClient.post('user/multiple-delete', {ids: JSON.stringify(ids)})
    //
    //     if (status === 200) {
    //       this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750});
    //       this.updatedFirstItem = null
    //       this.searchData = ''
    //
    //       this.getItems()
    //       this.closeConfirmItemsMultipleDeleteModal()
    //     }
    //   } catch(err) {
    //     console.log(err)
    //   }
    // },
    computedRole(roleNum) {
      return roles[this.$i18n.locale].filter(role => role.num === roleNum)[0].name
    },
    // async deleteFirstItem() {
    //   const { status } = await httpClient.delete(`user/${this.itemToDelete.id}`)
    //   if (status === 204) {
    //     this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750});
    //     this.updatedFirstItem = null
    //   }
    //   this.getItems()
    //   this.getFirstData()
    //   this.closeConfirmFirstItemDeleteModal()
    // },
    // confirmFirstItemsMultipleDelete() {
    //   this.confirmFirstItemsMultipleDeleteModal = true
    //   this.$store.commit('toggleFirstLayer', true)
    // },
    // closeConfirmFirstItemsMultipleDeleteModal() {
    //   this.itemToDelete = {}
    //   this.confirmFirstItemsMultipleDeleteModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    // },
    // async deleteMultipleFirstItems() {
    //   const ids = this.selectedItems.map(item => item)
    //   const { status } = await httpClient.post('user/multiple-delete', {ids: JSON.stringify(ids)})
    //
    //   if (status === 200) {
    //     this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750});
    //     this.updatedFirstItem = null
    //   }
    //   this.getItems()
    //   this.getFirstData()
    //   this.closeConfirmFirstItemsMultipleDeleteModal()
    // },
    // !!!
    // confirmSecondItemDelete(itemToDelete) {
    //   this.itemToDelete = itemToDelete
    //   this.confirmSecondItemDeleteModal = true
    //   this.$store.commit('toggleFirstLayer', true)
    // },
    // closeConfirmSecondItemDeleteModal() {
    //   this.itemToDelete = {}
    //   this.confirmSecondItemDeleteModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    // },
    // async deleteSecondItem() {
    //   const { status } = await httpClient.delete(`company/${this.itemToDelete.id}`)
    //   if (status === 204) {
    //     this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750});
    //     this.updatedSecondItem = null
    //   }
    //   this.getItems()
    //   this.getSecondData()
    //   this.closeConfirmSecondItemDeleteModal()
    // },
    // confirmSecondItemsMultipleDelete() {
    //   this.confirmSecondItemsMultipleDeleteModal = true
    //   this.$store.commit('toggleFirstLayer', true)
    // },
    // closeConfirmSecondItemsMultipleDeleteModal() {
    //   this.itemToDelete = {}
    //   this.confirmSecondItemsMultipleDeleteModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    // },
    // async deleteMultipleSecondItems() {
    //   const ids = this.selectedItems.map(item => item)
    //   console.log(ids)
    //
    //   const { status } = await httpClient.post('company/multiple-delete',{ids: JSON.stringify(ids)})
    //   if (status === 200) {
    //     this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750});
    //     this.updatedSecondItem = null
    //   }
    //   this.getItems()
    //   this.getSecondData()
    //   this.closeConfirmSecondItemsMultipleDeleteModal()
    // },
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.items.length; i++) {
				if (this.items[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	},
  computed: {
    paginationText() {
      if (this.pagination.currentPage === 1) {
        return `Showing ${this.pagination.currentPage} to ${this.pagination.perPage} of ${this.pagination.totalCount} records`
      } else if (this.pagination.currentPage === this.pagination.pageCount) {
        return `Last page`
      } else {
        return `Showing ${(this.pagination.currentPage - 1) * this.pagination.perPage + 1} to ${(this.pagination.currentPage - 1) * this.pagination.perPage + this.pagination.perPage} of ${this.pagination.totalCount} records`
      }
    },
    computedHeaderStyle() {
      if (this.$store.state.user.role === 0 || this.$store.state.user.role === 1) {
        return 'width: 3rem'
      } else {
        return 'width: 0px'
      }
    },
    isFirstData() {
      return (this.selectedDataType === CommonTranslations.clientTypes[this.$i18n.locale][0])
    },
    isSecondData() {
      return (this.selectedDataType === CommonTranslations.clientTypes[this.$i18n.locale][1])
    },
    itemsArrayIsEmpty() {
        return !(this.items && this.items.length)
    },
    actionButtonsCount() {
      if (this.$store.state.user.role === 0 || this.$store.state.user.role === 1) {
        return 3
      } else {
        return 2
      }
    },
    itemToDeleteData() {
      if (this.itemToDelete.role === 8) {
        return this.itemToDelete.company_name
      } else {
        return (this.itemToDelete.first_name ? (this.itemToDelete.first_name) : '')  + (this.itemToDelete.last_name ? ' ' + this.itemToDelete.last_name : '')
      }
    },
  }
}
</script>

<style scoped lang="scss">

// Original

//.item-image {
//	width: 100px;
//	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//}
//
//.p-dialog .item-image {
//	width: 150px;
//	margin: 0 auto 2rem auto;
//	display: block;
//}
//
//.confirmation-content {
//	display: flex;
//	align-items: center;
//	justify-content: center;
//}

.item-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

//::v-deep(.p-datatable.p-datatable-customers) {
//	.p-datatable-header {
//		padding: 1rem;
//		text-align: left;
//		font-size: 1.5rem;
//	}
//	.p-paginator {
//		padding: 1rem;
//	}
//	.p-datatable-thead > tr > th {
//		text-align: left;
//	}
//	.p-datatable-tbody > tr > td {
//		cursor: auto;
//	}
//
//	.p-dropdown-label:not(.p-placeholder) {
//		text-transform: uppercase;
//	}
//}
/* Responsive */
//.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
//	display: none;
//}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child{
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}
		}
	}

	::v-deep(.p-toolbar) {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
	}
}
</style>
